import React, { useMemo } from 'react'
import { I18n, hooks } from '@front/volcanion'
import TimeUtils from '@front/volcanion/utils/time'

import moment from 'moment'

import { Chip, TableCell, Box, Icon } from '@mui/material'

import { TextCell } from '@abra/elements/table/cells/basics/'
import { OrderLink, DriverLink, UserLink } from '@abra/elements'

const Row = ({ row }) => {
  const [{ name: operator_name }] = hooks.useCustomerInfo(row?.operator)
  const [{ name: driver_name }] = hooks.useCustomerInfo(row?.driver)
  const [{ name: client_name }] = hooks.useCustomerInfo(row?.order?.do)

  const { status, color } = useMemo(() => {
    if (row.active === 1) return { status: 'event.keys.active', color: 'warning' }
    if (row.action === 'approve' && !!row.processedAt) return { status: 'event.keys.approve', color: 'success' }
    else if (row.action === 'refuse' && !!row.processedAt) return { status: 'event.keys.refuse', color: 'error' }
    else return { status: 'event.keys.canceled', color: 'error' }
  }, [])

  return (
    <>
      <TableCell ><TextCell value={I18n.t(`event.${row.name}`)} /></TableCell>
      <TableCell >
        <Box display='flex' alignItems='center' justifyContent={'center'}>
          <Icon color='primary'>{'event'}</Icon>
          <TextCell sx={{ ml: 1 }} value={TimeUtils.getFormat(row.producedAt, 'calendar')} />
        </Box>
      </TableCell>
      <TableCell >
        {row.driver ? (
          <DriverLink record_id={row.driver}>
            <TextCell value={driver_name} />
          </DriverLink>
        ) : '-'}
      </TableCell>
      <TableCell >
        {row.do ? (
          <UserLink record_id={row.order.do}>
            <TextCell value={client_name} />
          </UserLink>
        ) : '-'}
      </TableCell>
      <TableCell >
        {row.order ? (
          <OrderLink record_id={row.order.order_id}>
            <TextCell value={row.order.client_id} />
          </OrderLink>
        ) : '-'}
      </TableCell>
      <TableCell >
        <Chip label={I18n.t(status)} variant='outlined' color={color} />
      </TableCell>
      <TableCell >
        <TextCell value={TimeUtils.getFormat(row.processedAt, 'calendar')} />
      </TableCell>
      <TableCell >
        <TextCell value={TimeUtils.secondsToTime(moment(row?.processedAt).diff(moment(row?.producedAt), 'seconds'))} />
      </TableCell>
      <TableCell >
        <TextCell value={operator_name} />
      </TableCell>
    </>
  )
}

export default React.memo(Row)
